.logo {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    border-radius: 50%;
    object-fit: cover;
}

.brand {
    max-width: 128px;
}

.navcontainer {
    background-color: #31446e;
}

.navbar {
    max-width: 1280px;
    margin: auto;
}

.navcontainergueststicky {
    background-color: #31446e;
    position: fixed;
    top: 0;
    width: 100%;
}

.navcontainerguest {
    background-color: #31446e;
}

.linkcontainer {
    cursor: pointer;
    color: #ffffff;
}

.sidebyside {
    display: flex;
}

.registerbutton {
    background: #17a2b8;
    margin-left: 25px;
    border: 0px;
}

.registerbutton:hover {
    background: #17a2b8;
}

.signinbutton {
    background: #ca590e;
    border: 0px;
}

.signinbutton:hover {
    background: #ca590e;
}