.container {
    display: flex;
    flex-direction: column;
}

.headingcontainer {
    display: flex;
    flex-direction: column;
}

.infocontainer {
    display: flex;
    flex: 1 1;
    justify-content: space-around;
}

.progresscontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    max-width: 750px;
}

.barheadercontainer {
    display: flex;
    justify-content: space-evenly;
}

.headerlabelcontainer {
    display: flex;
    flex: 1;
    justify-content: center;
}

.maincontainer {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.headerlabel {
    font-size: 20px;
    font-weight: bold;
}

.heading {
    padding-bottom: 10px;
    border-bottom: 3px solid #26a749;
}

.subheading {
    font-size: 22px;
    padding-bottom: 5px;
    border-bottom: 3px solid #31446e;
}

.indexedStep {
    color: #ffffff;
    width: 30px;
    height: 30px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.accomplishedStep {
    color: #ffffff;
    width: 30px;
    height: 30px;
    font-size: 12px;
    background-color: rgba(0, 116, 217, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.verifyform {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 500px;
}

.signviewcontainer {
    display: flex;
    flex-wrap: wrap;
}

.sigform {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.pdfcontainer {
    display: flex;
    flex-direction: column;
    flex: 4;
    margin-left: 50px;
    margin-right: 0px;
    overflow: hidden;
}

.portraitpdfcontainer {
    display: flex;
    flex-direction: column;
    flex: 4;
    margin: 0px;
    overflow: hidden;
}

.subheadingcontainer { 
    display: flex;
    border-bottom: 3px solid #31446e;
    margin-bottom: 5px;
    justify-content: space-between;
}

.subheader {
    font-size: 22px;
    padding-bottom: 5px;
}

.subheaderbutton {
    white-space: nowrap;
    margin-bottom: 5px;
}

.loading {
    display: flex;
    flex: 1;
    background-color: gray;
    min-height: 600px;
    max-height: 600px;
}

.loadingimg {
    margin: auto;
}

.crselect {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.sigfg {
    margin-top: 10px;
}

.mscomp div {
    white-space: normal;
}