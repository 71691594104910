.form {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
}

.container {
    display: flex;
}

.submit {
    flex: 1;
}