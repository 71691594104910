.container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.headingcontainer {
    display: flex;
    flex-direction: column;
}

.datecontainer { 
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}

.managertablecontainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 50px;
}

.employeetablecontainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 50px;
}

.vertcontainer {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 125px;
}

.vertflexedcontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}

.horcontainer {
    display: flex;
    flex: 1;
    align-content: space-between;
}

.horcontainerheader {
    display: flex;
    flex: 1;
    align-content: space-between;
    border-bottom: 1px solid #808080;
}

.labelcontainer {
    display: flex;
    flex: 1;
    justify-content: center;
}

.managerlabelcontainer {
    display: flex;
    flex: 1;
    justify-content: flex-start;
}

.documentcontainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 50px;
}

.heading {
    font-size: 20px;
    padding-bottom: 10px;
    border-bottom: 3px solid #31446e;
}

.headerlabel {
    font-weight: bold;
}

.date {
    flex: 1 0;
}

.tableheader {
    display: flex;
    justify-content: space-evenly;
}

.indexedStep {
    color: #ffffff;
    width: 30px;
    height: 30px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.accomplishedStep {
    color: #ffffff;
    width: 30px;
    height: 30px;
    font-size: 12px;
    background-color: rgba(0, 116, 217, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.incompletedStep {
    color: #ffffff;
    width: 30px;
    height: 30px;
    font-size: 12px;
    background-color: #dc3545;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewc {
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
    color: #dc3545;
}

.viewa {
    width: 35px !important;
    height: 35px !important;
    margin-left: 5px;
    cursor: pointer;
    color: #31446e;
}

.lname {
    margin-left: 5px;
}