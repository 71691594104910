.container {
    display: flex;
    flex-direction: column;
}

.subcontainer {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0;
    justify-content: space-evenly;
    margin-bottom: 35px;
}

.form {
    flex: 1 1;
    max-width: 500px;
    margin-top: 35px;
}

.heading {
    font-size: 22px;
    padding-bottom: 10px;
    border-bottom: 3px solid #31446e;
}

.subheading {
    font-size: 22px;
    padding-bottom: 5px;
    border-bottom: 3px solid #31446e;
}

.button {
    flex: 1;
    max-width: 125px;
}

.loading {
    width: 25px;
    height: 25px;
}

.crselect {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.conditionalrequirement {
    margin-left: 50px;
}