.container {
    display: flex;
    flex: 1 0 600px;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.detailcontainer {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 5px 25px 5px 25px;
}

.horcontainer {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.areacontainer {
    display: flex;
    margin: auto;
}

.wrapcontainer {
    display: flex;
    flex-wrap: wrap;
}

.headingcontainer {
    display: flex;
    flex: 1;
}

.main {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 500px;
}

.typecontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 15px;
}

.heading {
    margin: auto;
}

.subheading {
    font-size: 20px;
}

.arealabel {
    width: 75px;
}

.button {
    display: flex;
    margin-left: 5px;
    padding: 5px;
}

.icon {
    margin: auto;
}
