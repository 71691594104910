.nodatacontainer {
    display: flex;
    flex: 0 0 500px;
    flex-direction: column;
}

.checkedimg {
    width: 200px;
    height: 200px;
    margin: auto;
}

.heading {
    font-size: 20px;
    margin: auto;
}

.para {
    margin: auto;
}