.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.headingcontainer {
    display: flex;
    flex: 1;
    justify-content: space-between;
    border-bottom: 3px solid #26a749;
}

.headingbutton {
    margin: 10px;
}

.header {
    margin: auto 0;
}

.edit {
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
}

.nodatacontainer {
    display: flex;
    flex: 0 0 500px;
    flex-direction: column;
}

.checkedimg {
    width: 200px;
    height: 200px;
    margin: auto;
}

.heading {
    font-size: 20px;
    margin: auto;
}

.para {
    margin: auto;
}

.largeTextInput {
    min-height: 700px;
}