.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.main {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 500px;
}

.headingcontainer {
    display: flex;
    flex: 1;
    border-bottom: 3px solid #26a749;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.typecontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 15px;
}

.heading {
    margin: auto;
}

.subheading {
    font-size: 20px;
}