.container {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    background-color: #ffffff;
    padding: 20px;
}

.heading {
    margin: auto;
}

.para {
    font-size: 13px;
    padding: 15px;
}