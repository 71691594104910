.modal {
    display: flex;
    flex-direction: column;
}

.modalbody {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
    margin: 0;
}

.sigcanvas {
    flex: 1;
    padding: 0;
    margin: 0;
    max-height: 150px;
}