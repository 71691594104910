.main {
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: #FFFFFF;
}

.container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: column;
}

.sidebar {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 50px;
}

.storesContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 25px auto;

}

body, html {
    height: 100%;
    margin: 0;
}

.pic {
    margin: auto;
    max-width:100%;
    display: block;
 
    height: auto; /* maintain aspect ratio*/
    margin: auto; /*optional centering of image*/
}

.badgeg {
    margin: auto;
    max-width: 200px;
    width: 200px;
}

.badgea {
    margin: auto;
    max-width: 160px;
    width: 160px;
}

h4{
    margin:0;
    padding:0;
}

h5{
    margin:0;
    padding:0;
}

.paraDesktop {
    padding-top: 50px;
    margin: auto;
    text-align: center;
    flex: 1;
    font-weight: bold;
}


.paraDesktopSubText {
    padding: 35px;
    max-width: 900px; 
    margin: auto;
    text-align: center;
    font-size: large;
    flex: 1;
}


.para {
    padding: 35px;
    margin: auto;
    text-align: center;
    flex: 1;
    max-width: 600px;
    font-size: large;
}

.picHeader {
    padding-top: 10px;
    padding:0;
    margin:0;
    text-align: center;
    flex: 1;
    font-weight: bold;
}

.picSubHeader {
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 35px;
    margin: auto;
    text-align: center;
    flex: 1;
    font-weight: lighter;
}