.container {
    display: flex;
    flex-direction: column;
    flex: 0 1 500px;
    margin: 0 auto;
}

.headingcontainer {
    display: flex;
    flex-direction: column;
    height: 50px;
    border-bottom: 3px solid #26a749;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.heading {
    margin: auto;
}

.title {
    font-size: 20px;
    margin: 0 auto;
}

.para {
    margin: 0 auto;
}

.listcontainer {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    background-color: #ffffff;
    margin-bottom: 50px;
}

.listitem {
    background-color: #ebebeb;
    cursor: pointer;
}

.list {
    overflow: auto;
}

.centercontainer {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.itemtext {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px 0;
}

.pendingchoice {
    border: 1px solid black;
    border-radius: 3px;
    margin: 5px;
    float: right;
}

.badge {
    display: flex;
}

.badgetext {
    margin: auto;
}

.check {
    width: 200px;
    margin: 0 auto;
}