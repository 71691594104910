.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: auto;
    max-width: 1280px;
    padding: 25px;
}

.headingcontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-bottom: 3px solid #26a749;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.heading {
    margin: auto 0;
}

.para {
    margin: auto 0;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
}