.container {
    margin-top: 15px;
}

.edit {
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
}

.nodatacontainer {
    display: flex;
    flex: 0 0 500px;
    flex-direction: column;
}

.checkedimg {
    width: 200px;
    height: 200px;
    margin: auto;
}

.heading {
    font-size: 20px;
    margin: auto;
}

.para {
    margin: auto;
}