.form {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.namecontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 15px;
}

.infocontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 15px;
}

.subheading {
    font-size: 20px;
}

.sectionheading {
    font-size: 20px;
    border-bottom: 3px solid #31446e;
}

.formsubgroup {
    display: flex;
    flex: 1;
}

.forminput {
    flex: 1;
}

.button {
    margin: 10px;
}