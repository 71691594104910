.container {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.logocontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 1.5vh;
}

.logoheadingcontainer {
    flex: 1;
    margin-left: 2vw;
}

.boardcontainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 2vh;
}

.uppercontainer {
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    margin-bottom: 50px;
}

.activitycontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.lowercontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.boardheadingcontainer {
    display: flex;
    flex-direction: column;
    height: 50px;
    border-bottom: 3px solid #26a749;
}

.headingcontainer {
    display: flex;
    flex-direction: column;
    height: 50px;
    border-bottom: 3px solid #26a749;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.heading {
    margin: auto;
}

.logo {
    width: 150px;
    height: 150px;
    background-color: #ebebeb;
    border: 1px solid #808080;
    border-radius: 5px;
    object-fit: cover;
}

.board {
    height: 40vh;
    background-color: #ebebeb;
    border-left: 1px solid #808080;
    border-right: 1px solid #808080;
    border-bottom: 1px solid #808080;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: auto;
}

.boardtable {
    border: 1px solid #c8ccd0;
    padding: 6px 13px;
}

.activity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    width: 125px;
    height: 125px;
    margin: 5px;
    border: 1px solid #31446e;
    border-radius: 5px;
    cursor: pointer;
}

.activity:hover {
    background-color: #c8ccd0;
}

.activitylabel {
    text-align: center;
    cursor: pointer;
    color: #31446e;
}

.activityicon {
    color: #31446e;
}

.tickertext {
    margin-right: 3vw;
}

.tickerdate {
    margin-bottom: 0;
}