.container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.form {
    flex: 1 0 720px;
    border: 0px;
}

.heading {
    margin: 5px 0;
}

.completebutton {
    flex: 1;
    width: 250px;
    margin: 5px auto;
}