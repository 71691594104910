.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.headingcontainer {
    display: flex;
    align-content: space-between;
    flex: 1;
    border-bottom: 3px solid #26a749;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.subheadingcontainer {
    display: flex;
    align-content: space-between;
    flex: 1;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.main {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 500px;
}

.wrapcontainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.nowrapcontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 15px;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.nodata {
    flex: 1;
    margin: auto;
}

.header {
    flex: 1;
}

.heading {
    margin: auto;
}

.subheading {
    font-size: 20px;
}

.year {
    max-width: 150px;
}

.button {
    display: flex;
    margin: auto 5px;
    padding: 5px;
}

.icon {
    margin: auto;
}

.orglabel {
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 20px;
}

.detailcontainer {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 5px 25px 5px 25px;
}

.horcontainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 25px;
}

.areacontainer {
    display: flex;
    margin: auto;
}

.typecontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 15px;
}

.arealabel {
    width: 75px;
}

.tablecontainer {
    margin-top: 25px;
    height: 200px;
    overflow: auto;
}