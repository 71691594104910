.container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 750px;
}

.headingcontainer {
    display: flex;
    flex-direction: column;
}

.heading {
    padding-bottom: 10px;
    border-bottom: 3px solid #26a749;
}

.form {
    display: flex;
    flex-direction: column;
    flex: 0 1 500px;
}

.crselect {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.confirmation {
    display: flex;
}

.complete {
    flex: 0 0 250px;
    margin: 20px auto;
}

.conditionalrequirement {
    margin-left: 50px;
}