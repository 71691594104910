.container {
    flex: 1;
    max-width: 500px;
}

.nodatacontainer {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 25px;
    margin: auto;
}

.para {
    margin: auto;
}

.popoverbody {
    min-width: 250px;
    max-height: 300px;
    overflow-y: auto;
    cursor: pointer;
}

.listgroup {
    height: 300px;
    overflow-y: auto;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.singlelistgroup {
    height: 100px;
    overflow-y: auto;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.listgroupitem {
    display: flex;
    justify-content: space-between;
}

.heading {
    padding-bottom: 10px;
    border-bottom: 3px solid #31446e;
}

.subheading {
    font-size: 22px;
    padding-bottom: 5px;
    border-bottom: 3px solid #31446e;
}

.labelcontainer{
    display: flex;
    justify-content: space-between;
}

.icon {
    padding-bottom:5px;
    cursor: pointer;
}

.delBtn {
    text-align: right;
}

.lgicontainer {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.emSpan {
    align-self: center;
}

.modal {
    display: flex;
    flex-direction: column;
}

.modalbody {
    display: flex;
    flex-direction: column;
    flex: 1;
}