.container {
    display: flex;
    flex-direction: column;
}

.headingcontainer {
    display: flex;
    flex-direction: column;
}

.maincontainer {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.subcontainer {
    display: flex;
    margin-top: 25px;
}

.paracontainer {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 100px;
}

.heading {
    padding-bottom: 10px;
    border-bottom: 3px solid #26a749;
}

.subheading {
    font-size: 22px;
    padding-bottom: 5px;
    border-bottom: 3px solid #31446e;
}

.boldpara {
    font-weight: bold;
}

.button {
    flex: 1;
    max-width: 125px;
}