.modal {
   display: flex;
   flex-direction: column;
}

.modalbody {
   display: flex;
   flex-direction: column;
   flex: 1;
   padding: 0;
   margin: 0;
}

.uploadlabel {
   cursor: pointer;
   flex: 1;
   margin: auto;
   padding: 3vh;
   font-size: 2vh;
   font-weight: bold;
   color: #384769;
}

.uploadicon {
   padding: .5vh;
   font-size: 2vh;
   color: #384769;
}

#upload-file {
   opacity: 0;
   position: absolute;
   z-index: -1;
}

.loading {
   height: 8vh;
}

.error {
   color: #721c24;
   background-color: #f8d7da;
   border-color: #f5c6cb;
   margin: 1vh;
   font-size: 1.2vh;
   text-align: center;
   border-radius: 0.4vh;
}

.bodycontainer {
   display: flex;
   flex: 1;
}

.completedlabel {
   flex: 1;
   font-size: 1.4vh;
   font-weight: bold;
   text-align: center;
   margin: auto;
}

.completed {
   flex: 1;
   margin: auto;
   font-size: 1.4vh;
}

.progress {
   margin: 1vh;
}