.container {
    margin-top: 15px;
}

.view {
    width: 35px !important;
    height: 35px !important;
    cursor: pointer;
}

.viewc {
    width: 35px !important;
    height: 35px !important;
    cursor: pointer;
    color: rgb(170, 9, 9);
}

.viewr {
    width: 35px !important;
    height: 35px !important;
    cursor: pointer;
    color: #31446e;
}

.nodatacontainer {
    display: flex;
    flex: 0 0 500px;
    flex-direction: column;
}

.checkedimg {
    width: 200px;
    height: 200px;
    margin: auto;
}

.heading {
    font-size: 20px;
    margin: auto;
}

.para {
    margin: auto;
}

.filtercontainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.filterinput {
    flex: 1 1 100px;
    max-width: 120px;
}

.filterbutton {
    margin-left: 10px;
}

.badge {
    background-color: #4768ff;
    height: 25px;
    min-width: 50px;
    margin: auto 25px;
    cursor: pointer;
    vertical-align: middle;
}

.btext {
    color: #ffffff;
    vertical-align: middle;
}

.ex {
    color: #252525;
    vertical-align: middle;
    margin-left: 15px;
}