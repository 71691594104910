.container {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    padding: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
}

.submit {
    flex: 1;
}

.heading {
    margin: auto;
}

.para {
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 20px;
    flex: 1;
}