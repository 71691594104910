.container {
    margin-top: 15px;
}

.view {
    width: 35px !important;
    height: 35px !important;
    cursor: pointer;
}

.taskbarcontainer {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
}

.templatetypecontainer {
    display: flex;
    flex-wrap: wrap;
}

.nodatacontainer {
    display: flex;
    flex: 0 0 500px;
    flex-direction: column;
}

.checkedimg {
    width: 200px;
    height: 200px;
    margin: auto;
}

.heading {
    font-size: 20px;
    margin: auto;
}

.para {
    margin: auto;
}

.filtercontainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.filterinput {
    flex: 1 1 175px;
    max-width: 175px;
}

.selectinput {
    flex: 1 1 120px;
    max-width: 120px;
}

.filterbutton {
    margin-left: 10px;
}

.badge {
    background-color: #4768ff;
    height: 25px;
    min-width: 50px;
    margin: auto 25px;
    cursor: pointer;
    vertical-align: middle;
}

.btext {
    color: #ffffff;
    vertical-align: middle;
}

.ex {
    color: #252525;
    vertical-align: middle;
    margin-left: 15px;
}

.bgbutton {
    margin-right: .5vw;
    min-width: 10vw;
}

.btntoolbar {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    margin-bottom: 2vh;
}