.container {
    display: flex;
    flex: 1 0;
    min-height: 600px;
    max-height: 600px;
    overflow: auto;
    background: gray;
    border: 1px solid #ced4da;
    touch-action: pan-x pan-y;
}

.document {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    touch-action: pan-x pan-y;
}

.page {
    margin-bottom: 25px;
    touch-action: pan-x pan-y;
}