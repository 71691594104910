.modal {
    display: flex;
    flex-direction: column;
}

.modalbody {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.infocontainer {
    display: flex;
    flex: 1;
}

.info {
    flex: 1;
    flex-direction: column;
}

.infosecond {
    flex: 1;
    flex-direction: column;
    margin-left: 10px;
}

.listgroup {
    max-width: 50%;
}

.label {
    margin-top: 10px;
}

.button {
    margin: 10px;
}

.notesarea {
    min-height: 100px;
}