.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.topcontainer {
    display: flex;
    flex: 1;
}

.headingcontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}

.botcontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.actioncontainer {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 10px;
}

.imglogo {
    height: 100px;
    width: 100px;
    margin: 10px;
    border-radius: 50%;
    object-fit: cover;
}

.formarea {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
}

.formgroup {
    flex: 1 1 200px;
    margin: 10px;
}

.formheading {
    flex: 1;
    margin: auto;
}

.certificationscontainer {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    margin-bottom: 2em;
}

.certificationsheadingcontainer {
    display: flex;
    justify-content: space-between;
}