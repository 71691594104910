.modal {
    display: flex;
    flex-direction: column;
}

.modalbody {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.button {
    margin: 10px;
}