.container {
    margin-top: 15px;
}

.imgpreview {
    width: 150px;
    height: 150px;
    margin-left: 50px;
    background-color: #ebebeb;
    border: 1px solid #808080;
    border-radius: 5px;
    object-fit: cover;
}

.boardheadingcontainer {
    display: flex;
    flex-direction: column;
    height: 50px;
    border-bottom: 3px solid #26a749;
}

.boardcontainer {
    display: flex;
    flex-direction: column;
    flex: 1 0 250px;
    height: 400px;
    margin: 10px;
}

.board {
    height: 400px;
    background-color: #ebebeb;
    border-left: 1px solid #808080;
    border-right: 1px solid #808080;
    border-bottom: 1px solid #808080;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: auto;
}

.heading {
    font-size: 22px;
    margin: auto;
}

.sidebyside {
    display: flex;
}

.confirmcontainer {
    margin-top: 100px;
}

.policy {
    font-size: 12px;
    color: #6b6b6b;
    font-style: italic;
}