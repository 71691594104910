.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.headingcontainer {
    display: flex;
    flex: 1;
    justify-content: space-between;
    border-bottom: 3px solid #26a749;
}

.header {
    margin: auto 0;
    padding-top: 15px;
    padding-bottom: 15px;
}

.calendarcontainer {
    margin-top: 15px;
    height: 750px;
}

.togglecontainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.headingbutton {
    margin: 10px;
    white-space: nowrap;
    max-width: 125px;
}

.label {
    margin-left: 10px;
}