.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.topcontainer {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    margin-bottom: 25px;
}

.headingcontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}

.avatarcontainer {
    display: flex;
    flex: 0;
    flex-direction: column;
    justify-content: center;
}

.botcontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.actioncontainer {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 10px;
}

.imglogo {
    height: 100px;
    width: 100px;
    margin: 10px;
    border-radius: 50%;
    object-fit: cover;
}

.formarea {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.formgroup {
    flex: 1 1 200px;
    margin: 10px;
}

.formheading {
    flex: 1;
    margin: auto;
}

.uploadbtnwrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.label {
    border-radius: 8px;
    margin-left: 20px;
    
}
  
.btn {
    border: 2px solid #26a749;
    color: #31446e;
    background-color: #e7e7e7;
    border-radius: 8px;
    margin-left: 20px;
}

.fileinput {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.crselect {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}