.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: auto;
    max-width: 1280px;
    padding: 25px;
}

.main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.heading {
    margin: auto 0;
}

.para {
    margin: auto 0;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.activitycontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.activity {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: #ebebeb;
    width: 125px;
    height: 125px;
    margin: 5px;
    border: 1px solid #808080;
    border-radius: 5px;
    cursor: pointer;
}

.activitylabel {
    text-align: center;
    cursor: pointer;
}