.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.headingcontainer {
    display: flex;
    flex: 1;
    justify-content: space-between;
    border-bottom: 3px solid #26a749;
}

.header {
    margin: auto 0;
    padding-bottom: 10px;
}