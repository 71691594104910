.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 50px;
}

.formarea {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.formgroup {
    flex: 1 1 200px;
    margin: 10px;
}

.formheading {
    flex: 1;
    margin: auto 0;
}

.label {
    border-radius: 8px;
    margin-left: 30px;
}
  
.btn {
    max-width: 100px;
}

.crselect {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.cardformgroup {
    max-width: 500px;
    margin-bottom: 50px;
}