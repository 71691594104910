.container {
    display: flex;
    flex-direction: column;
    max-width: 720px;
    padding: 20px;
}

.subcontainer {
    display: flex;
    flex-direction: column;
    margin: 25px auto;
    max-width: 400px;
}

.ytcontainer{
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}

.ytcontainer iframe{
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.mainheading {
    margin: auto;
}

.heading {
    margin: auto;
}

.para {
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 20px;
}