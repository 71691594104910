.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.headingcontainer {
    display: flex;
    flex: 1;
    border-bottom: 3px solid #26a749;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.main {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 500px;
}

.subcontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 15px;
}

.formgroup {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.formsubgroup {
    display: flex;
    flex: 1;
}

.heading {
    margin: auto;
}

.subheading {
    margin-bottom: 15px;
}

.sectionheading {
    font-size: 22px;
    border-bottom: 3px solid #31446e;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.biginput {
    flex: 4;
}

.smallinput {
    flex: 1;
    margin-left: 10px;
}

.button {
    flex: 1;
    white-space: nowrap;
    max-width: 125px;
}

.notesarea {
    min-height: 100px;
}