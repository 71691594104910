.form {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
}

.container {
    display: flex;
}

.subcontainer {
    margin-bottom: 50px;
}

.submit {
    flex: 1;
    max-width: 200px;
    margin: 50px auto;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}