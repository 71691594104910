.container {
    display: flex;
    flex: 1 0 130px;
    overflow-x: auto;
    justify-content: space-between;
}

.smallcontainer {
    justify-content: space-evenly;
}

.selectablecontainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 0 100px;
    height: 100px;
    margin: 10px;
    cursor: pointer;
    background-color: #cccccc;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
}

.selectable {
    display: flex;
    flex: 0 0 40px;
    width: 40px;
    border: 1px solid #31446e;
    background-color: #d4d4d4;
    border-radius: 50%;
    margin: 5px auto;
}

.selectableicon {
    margin: auto;
    width: 30px !important;
    height: 30px !important;
}

.selectedcontainer {
    background-color: #f3f3f3;
}

.selected {
    background-color: #fcfcfc;
}

.label {
    font-size: 11px;
    text-align: center;
    margin: auto;
    padding-right: 5px;
    padding-left: 5px;
}