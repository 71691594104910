.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1280px;
    margin: auto;
    padding: 25px;
}

.main {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 500px;
}

.headingcontainer {
    display: flex;
    flex: 1;
    border-bottom: 3px solid #26a749;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.typecontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 15px;
}

.heading {
    margin: auto;
}

.subheading {
    margin-bottom: 5px;
}

.formsubgroup {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.listgroup {
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 300px;
    overflow-y: auto;
    margin-bottom: 15px;
}

.listgroupitem {
    display: flex;
    justify-content: space-between;
}

.input {
    flex: 3;
}

.add {
    margin-left: 10px;
}

.nodatacontainer {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 25px;
    margin: auto;
}

.para {
    margin: auto;
}

.button {
    margin: 10px;
}