.container {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    padding: 20px;
}

.heading {
    margin: auto;
}

.para {
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 20px;
    flex: 1;
}